var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Grid", {
        ref: "grid",
        attrs: {
          route_name: "notifications",
          order_column: "created_at",
          hide_searchbar: true,
          service: _vm.service,
        },
        on: {
          "grid-UPDATED": _vm.gridChanged,
          selected: function ($event) {
            return _vm.viewNotification($event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c("vs-th", { key: "icon" }),
                _c("vs-th", { key: "title" }, [
                  _vm._v(_vm._s(_vm.$t("classes_title"))),
                ]),
                _c("vs-th", { key: "message" }, [
                  _vm._v(_vm._s(_vm.$t("conteudo"))),
                ]),
                _c(
                  "vs-th",
                  { key: "created_at", attrs: { "sort-key": "created_at" } },
                  [_vm._v(_vm._s(_vm.$t("data-0")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "gridActions",
            fn: function () {
              return [_c("div")]
            },
            proxy: true,
          },
          {
            key: "gridData",
            fn: function (props) {
              return _vm._l(props.data, function (tr, indextr) {
                return _c(
                  "vs-tr",
                  {
                    key: indextr,
                    class: _vm.formatStatus(tr),
                    attrs: { data: tr },
                  },
                  [
                    _c(
                      "vs-td",
                      { key: "icon", attrs: { width: "5%" } },
                      [
                        _c("feather-icon", {
                          attrs: {
                            icon: _vm.formatIconName(tr.dropdownIcon),
                            svgClasses: [
                              `text-${tr.dropdownCategory}`,
                              "stroke-current mr-1 h-6 w-6",
                            ],
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "vs-td",
                      {
                        key: "title",
                        class: [`text-${tr.dropdownCategory}`],
                        attrs: { width: "25%" },
                      },
                      [_vm._v(_vm._s(tr.dropdownTitle))]
                    ),
                    _c("vs-td", { key: "message", attrs: { width: "50%" } }, [
                      _vm._v(_vm._s(tr.message(50))),
                    ]),
                    _c(
                      "vs-td",
                      { key: "created_at", attrs: { width: "20%" } },
                      [_vm._v(_vm._s(_vm.formatDate(tr.created_at)))]
                    ),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("detalhes-notificacao"),
            active: _vm.activeDetails,
          },
          on: {
            "update:active": function ($event) {
              _vm.activeDetails = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "grid grid-cols-12" },
            [
              _c(
                "div",
                { staticClass: "mb-4 col-span-12 justify-self-center" },
                [
                  _c(
                    "div",
                    { staticClass: "flex items-center" },
                    [
                      _vm.notificationDetails.dropdownIcon
                        ? _c("feather-icon", {
                            attrs: {
                              icon: _vm.formatIconName(
                                _vm.notificationDetails.dropdownIcon
                              ),
                              svgClasses: [
                                `text-${_vm.notificationDetails.dropdownCategory}`,
                                "stroke-current mr-1 h-6 w-6",
                              ],
                            },
                          })
                        : _vm._e(),
                      _vm.notificationDetails.dropdownTitle
                        ? _c(
                            "span",
                            {
                              class: `text-${_vm.notificationDetails.dropdownCategory} font-bold text-lg`,
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.notificationDetails.dropdownTitle)
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticClass:
                    "text-lg text-center font-bold col-span-12 border-grey border-b border-t-0 border-r-0 border-l-0 border-solid",
                },
                [_vm._v(_vm._s(_vm.title))]
              ),
              _vm.notificationDetails.type === "answer.comment" &&
              _vm.notificationDetails.comment.questionnaire_name
                ? _c("div", { staticClass: "mb-3 mt-4 col-span-12" }, [
                    _c("span", { staticClass: "block font-bold mb-1" }, [
                      _vm._v(_vm._s(_vm.$t("Questionnaire"))),
                    ]),
                    _c(
                      "a",
                      {
                        staticClass: "block mb-2",
                        attrs: {
                          target: "_blank",
                          href: _vm.notificationDetails.comment.answer_url,
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.notificationDetails.comment.questionnaire_name
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _vm.notificationDetails.comment.author_name
                      ? _c("div", { staticClass: "block mb-1" }, [
                          _c("span", { staticClass: "font-bold" }, [
                            _vm._v("Autor: "),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.notificationDetails.comment.author_name
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.notificationDetails.type === "answer.comment"
                ? _c("span", {
                    staticClass:
                      "text-lg text-center font-bold col-span-12 border-grey border-b border-t-0 border-r-0 border-l-0 border-solid",
                  })
                : _vm._e(),
              _vm.notificationDetails.type === "answer.comment"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "my-4 col-span-12 justify-self-start grid break-all",
                    },
                    [
                      _vm.notificationDetails.message
                        ? _c("div", {
                            directives: [
                              {
                                name: "html-safe",
                                rawName: "v-html-safe",
                                value: _vm.notificationDetails.message(),
                                expression: "notificationDetails.message()",
                              },
                            ],
                          })
                        : _vm._e(),
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "my-4 col-span-12 justify-self-start grid break-all",
                    },
                    [
                      _vm.notificationDetails.message
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.notificationDetails.message())),
                          ])
                        : _vm._e(),
                    ]
                  ),
              _vm.notificationDetails.onClick &&
              _vm.notificationDetails.type !== "answer.comment"
                ? _c(
                    "vs-button",
                    {
                      staticClass: "justify-self-end col-span-12",
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.openNotificationAction(
                            _vm.notificationDetails
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("abrir")))]
                  )
                : _c(
                    "span",
                    { staticClass: "justify-self-center col-span-12" },
                    [_vm._v(_vm._s(_vm.url))]
                  ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }